<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
      <!-- Add Order -->
      <!-- <div class="row page-titles mx-0">
          <div class="col-sm-6 p-md-0">
              <div class="welcome-text">
                  <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a href="javascript:void(0)">Reports</a></li>
                      <li class="breadcrumb-item active"><a href="javascript:void(0)" (click)="reloadPage()">Tagihan Shipper</a></li>
                  </ol>
              </div>
          </div>
      </div> -->
<!-- 
      <div class="d-flex align-items-center flex-wrap mb-3">
        <div class="input-group search-area-2 mb-3 mr-auto d-inline-flex">
        </div> -->
        <!-- <a href="javascript:void(0);" class="btn btn-outline-primary btn-rounded mb-3 mr-3" (click)="openfilter(sendMessageModalfilter)"><i class="fa la-search scale5 mr-2"></i>Form Filter</a> -->
        <!-- <a href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3" (click)="open(sendMessageModal)"><i class="fa fa-plus scale5 mr-2"></i>Create</a> -->
        <!-- <a href="javascript:void(0);" (click)="reloadPage()" class="btn btn-danger btn-rounded mb-3 mr-3"><i class="fa fa-refresh scale5 mr-2"></i>Refresh</a>

    </div> -->


    <div class="row page-titles" style="margin-bottom: 0px;">
      <div class="col-sm-6 p-md-0 ">
        <div class="welcome-text">
          <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="javascript:void(0)">Reports</a></li>
              <li class="breadcrumb-item active"><a href="javascript:void(0)" (click)="reloadPage()">Tagihan Shipper</a></li>
          </ol>
      </div>
      </div>
      <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
        <a href="javascript:void(0);" (click)="reloadPage()" class="btn btn-danger btn-rounded mb-3 mr-3"><i class="fa fa-refresh scale5 mr-2"></i>Refresh</a>
    </div>
    </div>
      <!-- row -->
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
                <h4 class="card-title">List Tagihan Shipper</h4>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Shipper</th>
                      <th scope="col">Jan</th>
                      <th scope="col">Feb</th>
                      <th scope="col">Mar</th>
                      <th scope="col">Apr</th>
                      <th scope="col">Mei</th>
                      <th scope="col">Jun</th>
                      <th scope="col">Jul</th>
                      <th scope="col">Agt</th>
                      <th scope="col">Sept</th>
                      <th scope="col">Okt</th>
                      <th scope="col">Nov</th>
                      <th scope="col">Dec</th>
                      <!-- <th scope="col">Kode</th>
                      <th scope="col">Jenis</th>
                      <th scope="col">Nama</th>
                      <th scope="col">Penerima</th>
                      <th scope="col">Airwaybill</th>
                      <th scope="col">Actual Weight</th>
                      <th scope="col">Jumlah</th>
                      <th scope="col">Sisa</th>
                      <th scope="col">Status</th>
                      <th scope="col">Vendor</th>
                      <th scope="col">User</th> -->
                    </tr>
                  </thead>
                    <tbody>
                      <tr *ngFor="let tutorial of tutorials; index as x">
                        <th scope="row">
                          {{ x+1 }}
                        </th>
                        <td>{{ tutorial.nameshipers }}</td>
                        <td>{{ currencyStr(tutorial.sisa_tagihan_jan) }}</td>
                        <td>{{ currencyStr(tutorial.sisa_tagihan_feb) }}</td>
                        <td>{{ currencyStr(tutorial.sisa_tagihan_maret) }}</td>
                        <td>{{ currencyStr(tutorial.sisa_tagihan_april) }}</td>
                        <td>{{ currencyStr(tutorial.sisa_tagihan_mei) }}</td>
                        <td>{{ currencyStr(tutorial.sisa_tagihan_juni) }}</td>
                        <td>{{ currencyStr(tutorial.sisa_tagihan_juli) }}</td>
                        <td>{{ currencyStr(tutorial.sisa_tagihan_agustus) }}</td>
                        <td>{{ currencyStr(tutorial.sisa_tagihan_september) }}</td>
                        <td>{{ currencyStr(tutorial.sisa_tagihan_oktober) }}</td>
                        <td>{{ tutorial.sisa_tagihan_november }}</td>
                        <td>{{ tutorial.sisa_tagihan_desember }}</td>
                        <!-- <td>{{ tutorial.no_invoice }}</td>
                        <td>{{ tutorial.tanggal_tagihan }}</td>
                        <td>{{ tutorial.kode_tagihan }}</td>
                        <td>{{ tutorial.jenis_tagihan }}</td>
                        <td>{{ tutorial.nama_tagihan }}</td>
                        <td>{{ tutorial.nameconsignee }}</td>
                        <td>{{ tutorial.airwaybill }}</td>
                        <td>{{ tutorial.actual_weight }}</td>
                        <td>{{ tutorial.jumlah_tagihan }}</td>
                        <td>{{ tutorial.sisa_tagihan }}</td>
                        <td>{{ tutorial.is_status_tagihan }}</td>
                        <td>{{ tutorial.nameshipers }}</td>
                        <td>{{ tutorial.namevendors }}</td>
                        <td>{{ tutorial.full_name }}</td> -->
                      </tr>
                    </tbody>
                </table>
               </div>

               <div class="d-flex flex-wrap justify-content-between p-2">
                <ngb-pagination class="pagination-responsive"
                  [collectionSize]="count"
                  [(page)]="page"
                  [pageSize]="pageSize"
                  (pageChange)="handlePageChange($event)" [maxSize]="10" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                >
                </ngb-pagination>
                <div class="form-group">
                  <label class="mr-sm-2">Show:</label>
                  <select
                    class="mr-sm-2 custom-select"
                    style="width: auto"
                    name="pageSize"
                    (change)="handlePageSizeChange($event)"
                  >
                    <option *ngFor="let size of pageSizes" [ngValue]="size">
                      {{ size }}
                    </option>
                  </select>
                  entries
                </div>
              </div>
            </div>
        </div>
        </div>

      </div>

  </div>
</div>
<!--**********************************
  Content body end
***********************************-->
<!-- Modal ADD -->


<!-- FILTER -->
<!-- Modal ADD -->
<ng-template #sendMessageModalfilter let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Form Filter</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<!-- <form class="comment-form"> -->
			<div class="row">
				<!-- <div class="col-lg-4">
					<div class="form-group">
						<label class="text-black font-w600">Tanggal Tagihan</label>
            <input
              type="date"
              class="form-control"
              id="tanggal_tagihan"
              [(ngModel)]="tanggal_tagihan"
              #name="ngModel"
              name="tanggal_tagihan"
            />
          </div>
				</div>
				<div class="col-lg-4">
					<div class="form-group">
						<label class="text-black font-w600">Invoice</label>
            <input
              type="text"
              class="form-control"
              id="no_invoice"
              [(ngModel)]="no_invoice"
              #name="ngModel"
              name="no_invoice"
            />
          </div>
				</div>
				<div class="col-lg-4">
					<div class="form-group">
						<label class="text-black font-w600">Airwaybill</label>
            <input
              type="text"
              class="form-control"
              id="airwaybill"
              [(ngModel)]="airwaybill"
              #name="ngModel"
              name="airwaybill"
            />
          </div>
				</div> -->
        <div class="col-lg-4 mb-3">
          <label class="text-black font-w600">Shipper</label>
            <select class="form-control default-select" *ngIf="vcombos.length > 0" id="shipper_id" [(ngModel)]="shipper_id" #name="ngModel" name="shipper_id">
              <option value="">Please select</option>
              <option *ngFor="let n of vcombos" value="{{n.id}}">{{n.name}}</option>
            </select>
        </div>
        <!-- <div class="col-lg-4 mb-3">
          <label class="text-black font-w600">Shipper</label>
            <select class="form-control default-select" *ngIf="vcombos.length > 0" id="shipper_id" [(ngModel)]="shipper_id" #name="ngModel" name="shipper_id">
              <option value="">Please select</option>
              <option *ngFor="let n of vcombos" value="{{n.id}}">{{n.name}}</option>
            </select>
        </div> -->
        <!-- <div class="col-lg-4 mb-3" *ngIf="isSuperadmin || isAdmin">
          <label class="text-black font-w600">Vendor</label>
            <select class="form-control default-select" *ngIf="vcomboss.length > 0" id="vendor_id" [(ngModel)]="vendor_id" #name="ngModel" name="vendor_id">
              <option value="">Please select</option>
              <option *ngFor="let n of vcomboss" value="{{n.id}}">{{n.name}}</option>
            </select>
        </div> -->

				<div class="col-lg-12">
					<div class="form-group mb-0">
            <button (click)="searchTitle()" class="submit btn btn-primary mb-3 mr-3">Search</button>
            <!-- <button (click)="downloadExcel()" class="submit btn btn-danger mb-3 mr-3"><i class="fa fa-file-excel-o mr-2"></i> Export Excel</button> -->
					</div>
				</div>
			</div>
		<!-- </form> -->
	</div>
</ng-template>


<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
